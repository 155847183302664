import * as React from "react";
import Header from "../components/Header/Header";
import Nav from "../components/Nav/Nav";

// styles

// markup
const NotFoundPage = () => {
  return (
    <>
      <Header/>
      <div className="wrapper">
        <Nav />
        <h1>404 — PAGE NOT FOUND ;(</h1>
      </div>
    </>
  )
}

export default NotFoundPage
